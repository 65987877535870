<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center">
                    <div class="text-center">
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<span v-b-modal.modal-3 variant="link" class="mb-0"><u>수집이 잘 되지 않는 경우 조치방법</u></span></p>
							<b-modal id="modal-3" size="sm" title="수집이 잘 되지 않는 경우 조치방법" ok-only>
								<p>1. 확장 설치 후 쿠플러스 사이트를 닫았다가 열어서 다시 로그인 후에 키워드 분석 시도하기</p>
								<p>2. 크롬 브라우저 사용하기</p>
								<p>3. 확장앱 중 1688/타오바오/알리바바 이미지서치 앱 삭제</p>
								<p>4. 크롬 브라우저의 계정을 별도의 계정으로 추가해서 쿠플러스만 확장 설치 후 실행하기</p>
                            </b-modal>
                    </div>
                </div>
			</div>
			<div class="col-lg-12">
				<div class="d-flex justify-content-center" style="height:150%">
					<input ref="cursor" type="text" class="form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="searchenter($event)">
					<div class="d-flex justify-content-center align-items-center ml-4">
						<button type="button" class="btn btn-primary" @click="search">키워드 분석</button>
					</div>
				</div>
			</div>
			<div class="col-lg-12 mt-5" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{loadingtext}}... {{loadingrate}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 d-flex justify-content-center flex-column mt-3" v-if="showlist">
				<b-card class="d-flex justify-content-center" style="width:450px;height:400px;">
					<h5 class="badge bg-warning">1page 카테고리 현황</h5>
					<ApexChart element="layout1-chart-5" :chartOption="chart" />
				</b-card>
				<b-card style="width:450px;">
					<div v-for="optionUnit,idx in optionUnits" :key="idx">
						<h5 class="badge bg-warning">최적 옵션단위 : {{optionUnit.unitName}}</h5>
						<card class="p-3">
							{{ optionUnit.units }}
						</card>
					</div>
				</b-card>
			</div>

			<div class="col-lg-9 mt-3" v-if="showlist" style="font-size:80%">
				<div class="table-responsive rounded mb-3">
                    <CDataTable :headers="headers" :items="categoryList" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
						<template v-slot:categoryFitness="{item}">
                            {{ (item.categoryFitness*100).toFixed(1) + "%" }}
                        </template>
                    </CDataTable>
				</div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import ApexChart from '../../../components/charts/ApexChart';
import CDataTable from "@/components/common/CDataTable";
// import { chrome } from 'vue-chrome-extension';
export default {
	name:'nomadKeywordAnalysis',
	components:{
        ApexChart,
        CDataTable
    },
	data(){
		return{
			optionUnits:[],
			headers: [
                {text: '카테고리명', value: 'categoryName', align: 'center', width: 400, isSlot: false},
                {text: '카테고리ID', value: 'categoryId', align: 'center', width: 120, isSlot: false},
                {text: '카테고리적합도', value: 'categoryFitness', align: 'center', width: 120, isSlot: true},
                // {text: '구매정보', value: '구매가', align: 'center', width: 250, isSlot: true},
                // {text: '매입가(원)', value: '매입가', align: 'center', width: 100, isSlot: false},
                // {text: '예상월수익(원)', value: '월수익', align: 'center', width: 140, isSlot: true},
                // {text: '예상마진(원) 마진율(%) ROI(%)', value: '마진', align: 'center', width: 100, isSlot: true},
                // {text: '판매가(원)', value: '판매가', align: 'center', width: 150, isSlot: true},
                // {text: '수수료(원)', value: '수수료', align: 'center', width: 100, isSlot: true},
                // {text: '예상월판매량(개)', value: '월판매량', align: 'center', width: 140, isSlot: true},
                // {text: '예상월매출(원)', value: '월매출', align: 'center', width: 140, isSlot: true},
                // {text: '리뷰수', value: '리뷰수', align: 'center', width: 100, isSlot: false},
                // {text: '리뷰평점', value: '리뷰평점', align: 'center', width: 100, isSlot: false},
                // {text: '연관키워드', value: '연관키워드', align: 'center', width: 120, isSlot: false},
                // {text: '메모', value: '메모', align: 'center', width: 120, isSlot: true},
            ],
            tableSelectedItem: [],
			categoryList:[],
			analysisType:'keywordSales',
			salesUnit:'daily',
			related:false,
			auth:'',
			loadingtext:'연관검색어 분석 중',
			loadingrate:'',

			showspinner:false,
			showlist:false,

			waitresult:false,
			targetKeyword:'',
			fullsearch:false,
			addlines: 10,
			rowlines:[],

			title:"키워드 카테고리 최적화",
			desc1:"키워드를 분석하면 1p에 분포하는 상품들의 카테고리 분포를 통해 최적화 카테고리를 알 수 있습니다.",
			desc2:"쿠팡윙을 새탭에 로그인한 상태에서 실행해 주세요. 많은 데이터 수집이 필요하므로 ip차단에 유의해 주세요.",
			desc3:"",
			monthSeries:[],
			dailySeries:[],
			chart:{
				chart: {
					type: 'pie',
					width: '100%',
					height: '100%'
				},
				legend: {
					show: false // 레전드를 숨깁니다.
				},
				dataLabels: {
					enabled: true,
					formatter: function(val, opts) {
						// 데이터 라벨 형식을 'categoryId : percentage%'로 설정
						return opts.w.config.labels[opts.seriesIndex].split(':')[1].trim() + ' : ' + val.toFixed(1) + '%';
					},
					style: {
						fontSize: '120%', // 데이터 라벨의 폰트 크기를 16px로 설정
						fontFamily: 'Helvetica, Arial, sans-serif', // 선택적으로 폰트 패밀리 설정
						fontWeight: 'bold' // 선택적으로 폰트 두께 설정
						// 추가적인 스타일 설정...
					},
					// 데이터 라벨의 나머지 스타일 설정...
				},
				// labels: inputData.map(item => `Category ${item.categoryId}`),
				// series: inputData.map(item => item.cnt),
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width:200
						}
					}
				}]
			},
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},	
    mounted() {
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'login'){
				alert('쿠팡wing에 로그인 해주세요')
				this.loadingtext = '';
				this.loadingrate = '';
				this.showspinner = false;
				this.showlist = false;
				return
			}
			if(event.data.res && event.data.res === 'keywordanalysisstatusratio'){
                console.log(event.data.status);
				this.loadingtext = event.data.text;
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
            }
            if(event.data.res && event.data.msg === 'getCategoryOptimization'){
				// if(!event.data.cpc){
				// 	alert('CPC단가는 쿠팡wing에 로그인 후 정상적으로 출력됩니다.')
				// }
                // console.log(event.data.coupang);
				// console.log(event.data.naver);
				console.log(event.data);
				if(!event.data.categoryInfo){
					alert('쿠팡wing의 로그인 상태를 다시 확인해주세요')
					return
				}
				
				this.loadingtext = '데이터 계산 중';
				this.loadingrate = "";
				this.showspinner = true;
				this.showlist = false;
				this.addlist(event.data)
				// this.addChart(event.data.coupangSales)


				// this.makerowlines(event.data.coupang,event.data.naver);
				// var obj = event.data.result;
				// this.rowlines[obj.index].rank = obj.rank;
				// this.rowlines[obj.index].adqty = obj.adqty;
				// this.rowlines[obj.index].adplace = obj.adplace;
				// this.rowlines[obj.index].productqty = obj.productqty;
				// this.rowlines[obj.index].rocketqty = obj.rocketqty;
				// this.rowlines[obj.index].totalrank = obj.totalrank;
				// this.waitresult = false;
				// await this.sleep(10);
				// this.showspinner = false;
				// this.showlist = true;
            }
            if(event.data.res && event.data.res === 'relatedkeywords'){
                console.log(event.data.relatedkeywords)
				var arr = event.data.relatedkeywords;
				var ar = [];
				if(this.related){
					ar = [this.targetKeyword]
				}
				// console.log(arr)
				arr.forEach(ele => {
					if(!ar.includes(ele)){
						ar.push(ele)
					}
				})
				var keywords = [];
				// console.log(ar)
				ar.forEach(ele => {
					if(!this.rowlines.find(element => element.targetKeyword == ele)){
						keywords.push(ele);
					}
				})
				// console.log(keywords)
				// this.rowlines = [];
				var cnt = 0
				for(var i=0;i<keywords.length;i++){
					cnt++
					this.rowlines.push({
						targetKeyword:keywords[i],
						wing1p:'',
						rocket1p:'',
						growth1p:'',
						adrate:'',
						adqty:'',
						adplace:'',
						cpc:'',
						cpchigh:'',
						cpclow:'',
						rocketrate:'',
						rocketqty:'',
						totalqty:'',
						crate:'',
						review1p:'',
						review1phigh:'',
						review1plow:'',
						review1pmiddle:'',
						n1psalesqty:'',
						n1psalesqtyhigh:'',
						n1psalesqtylow:'',
						n1psalesqtymiddle:'',
						n1psales:'',
						n1psaleshigh:'',
						n1psaleslow:'',
						n1psalesmiddle:'',	
										
						nrate:'',
						pricen1phigh:'',
						pricen1plow:'',
						pricen1p:'',
						pricen1pmiddle:'',
						price1phigh:'',
						price1plow:'',
						price1p:'',
						price1pmiddle:'',
						wingrate:'',
					})
					
				}
				if(cnt == keywords.length){
					this.importresults(keywords)
				}
            }
		}
		window.addEventListener('message', this.messageEventHandler);
		console.log(navigator)
		this.getauthNo()
		this.startfocus();
	},
	methods: {
		addlist(data){
			console.log(data)
			var inputData = [];
			for(var e of data.categoryIds){
				if(inputData.length > 0 && inputData.find(f => f.categoryId == e.categoryId)){
					inputData.find(f => f.categoryId == e.categoryId).cnt++
				} else {
					inputData.push({
						categoryId : e.categoryId,
						cnt : 1
					})
				}
			}
			inputData.forEach(e => {
				e.fitness = e.cnt/data.categoryIds.length
			})
			this.chart.labels = inputData.map(item => `Category : ${item.categoryId}`);
			this.chart.series = inputData.map(item => item.cnt);
			
			this.categoryList = [];
			for(e of data.categoryInfo){
				if(inputData.find(f => f.categoryId == e.categoryId)){
					var fitness = inputData.find(f => f.categoryId == e.categoryId).fitness
				} else {
					fitness = 0
				}
				this.categoryList.push({
					categoryName : e.category,
					categoryId : e.categoryId,
					categoryFitness : fitness
				})
			}
			this.categoryList.sort((a,b)=>{return b.categoryFitness-a.categoryFitness});
			var options = data.optionUnit ? data.optionUnit : [];
			this.optionUnits = [];
			for(e of options){
				if(e.unitGroupDto){
					if(this.optionUnits.length == 0 || !this.optionUnits.find(f => f.unitName == e.unitGroupDto.description)){
						this.optionUnits.push({
							unitName : e.unitGroupDto.description,
							units : e.unitGroupDto.units.map(f => f.description).join(", ")
						})
					}
				}
			}
			console.log(this.optionUnits)
			if(this.optionUnits.length == 0){
				this.optionUnits.push({
					unitName : '없음(단일옵션)',
					units : ""
				})
			}

			setTimeout(()=>{
				this.showspinner = false;
				this.showlist = true;
			},1)
		},
		changeSalesUnit(){
			console.log(this.salesUnit)
			this.showlist = false;
			if(this.salesUnit == 'monthly'){
				this.chart.series = this.monthSeries;
			} else {
				this.chart.series = this.dailySeries;
			}
			setTimeout(()=>{
				this.showlist = true;
			},1)
		},
		addChart(s){
			var ylength = s.reduce((pv,cv) => {return pv <= cv.length ? pv : cv.length},100);
			if(this.analysisType == 'keywordSales'){
				var dates = 30;
				this.chart.tooltip.y.formatter = function (val) {
					return val.toLocaleString() + " 원"
				}
			} else {
				dates = 28;
				this.chart.tooltip.y.formatter = function (val) {
					return val.toLocaleString() + " 회"
				}
			}
			for(var e of s){
				this.monthSeries.push(
					{
						name : e[0].keyword,
						data : e.map(f => f.monthSales).sort(function (a, b){return b - a})
					}
				)
				this.dailySeries.push(
					{
						name : e[0].keyword,
						data : e.map(f => parseInt(f.monthSales/dates)).sort(function (a, b){return b - a})
					}
				)								
			}
			var x = [];
			for (let i = 0; i < ylength; i++) {
				x.push(i+1);
			}
			this.chart.xaxis.categories = x;


			this.changeSalesUnit();
			setTimeout(()=>{
				this.showlist = true;
				this.showspinner = false;
			},1)
		},
		async getauthNo(){
			axios.post('/api/getAuthNo')
			.then(res => {
				this.auth = res.data.auth;
			}).catch(console.log)
		},
		resetitems(){
			this.rowlines = [];
		},
		searchenter(event){
			if(event.keyCode === 13){
				this.search();
			}
		},
		startfocus(){
			this.$refs.cursor.focus();
		},
		middlelevel(arr) {
			arr.sort((a,b) => a-b);
			var val = parseInt(arr.length/2);
			return arr[val];
		},
		async makerowlines(coupang,naver){
			// var cpcobj = cpc;
			console.log(coupang)
			console.log(naver)
			// console.log(cpc)
			this.rowlines.forEach(e => {
				// console.log(coupang)
				// console.log(e)
				if(coupang.find(ele => ele.keyword == e.targetKeyword)){
					var coupang1p = coupang.find(ele => ele.keyword == e.targetKeyword);
					var coupang1plist = coupang1p.productlists1p.filter(ele => ele.ad == false);
					// console.log(coupang1plist.map(ele => ele.ratingcount))
					var naver1p = naver.find(ele => ele.keyword == e.targetKeyword).keywordvalue;
					var naverlist = naver.find(ele => ele.keyword == e.targetKeyword).products;
					// console.log(naverlist.filter(ele => ele.purchaseCnt >= 0))
					for(var j=0;j<naver1p.length;j++){
						naver1p[j].long = parseInt(naver1p[j].long > naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/26 ? naver1p[j].long : naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/26);
						
					}

					// var cpc = cpcobj ? cpcobj[e.targetKeyword] : '';
					
					e.wing1p = coupang1plist.filter(ele => ele.type == 'wing').length;
					e.rocket1p = coupang1plist.filter(ele => ele.type == 'rocket').length;
					e.growth1p = coupang1plist.filter(ele => ele.type == 'jet').length;
					e.wingrate = (e.wing1p/(e.wing1p+e.rocket1p+e.growth1p)*100).toFixed(2);
					// e.adqty = coupang1p.adqty;
					// e.adplace = coupang1p.adplace;
					// e.adrate = (coupang1p.adqty/coupang1p.adplace*100).toFixed(2);
					// e.cpchigh = cpc ? cpc.high : 0;
					// e.cpclow = cpc ? cpc.low : 0;
					// e.cpc = cpc ? cpc.suggested : 0;
					e.rocketqty = coupang1p.rocketqty;
					e.totalqty = coupang1p.totalqty;
					e.rocketrate = (coupang1p.rocketqty/coupang1p.totalqty*100).toFixed(2);
					e.review1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.ratingcount ? pv : cv.ratingcount},0);
					e.review1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.ratingcount ? pv : cv.ratingcount},1000000);
					e.review1p = (coupang1plist.reduce((pv,cv)=>{return pv + cv.ratingcount},0)/coupang1plist.length).toFixed(0);
					e.review1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.ratingcount));

					e.price1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0).toLocaleString();
					e.price1plow = parseInt(coupang1plist.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},1000000)).toLocaleString();
					e.price1p = parseInt(coupang1plist.reduce((pv,cv)=>{return pv + cv.price},0)/coupang1plist.length).toLocaleString();
					e.price1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.price));

					e.pricen1phigh = parseInt(naver1p.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0)).toLocaleString();
					e.pricen1plow = parseInt(naver1p.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},1000000)).toLocaleString();
					e.pricen1p = parseInt(((naver1p.reduce((pv,cv)=>{return pv + cv.price},0))/naver1p.length)).toLocaleString();
					e.pricen1pmiddle = parseInt(this.middlelevel(naver1p.map(ele => ele.price))).toLocaleString();
					// e.crate = (e.review1p/e.totalqty).toFixed(2);
					e.n1psalesqtyhigh = parseInt(naver1p.reduce((pv,cv)=>{return pv > cv.long ? pv : cv.long},0)/7*30).toLocaleString();
					e.n1psalesqtylow = parseInt(naver1p.reduce((pv,cv)=>{return pv < cv.long ? pv : cv.long},100000000)/7*30).toLocaleString();
					e.n1psalesqty = parseInt(((naver1p.reduce((pv,cv)=>{return pv + cv.long},0))/naver1p.length)/7*30).toLocaleString();
					e.n1psalesqtymiddle = parseInt(this.middlelevel(naver1p.map(ele => ele.long))/7*30).toLocaleString();

					e.n1psaleshigh = parseInt(naver1p.reduce((pv,cv)=>{return pv > cv.long*cv.price ? pv : cv.long*cv.price},0)/7*30).toLocaleString();
					e.n1psaleslow = parseInt(naver1p.reduce((pv,cv)=>{return pv < cv.long*cv.price ? pv : cv.long*cv.price},100000000)/7*30).toLocaleString();
					e.n1psales = parseInt(((naver1p.reduce((pv,cv)=>{return pv + cv.long*cv.price},0))/naver1p.length)/7*30).toLocaleString();
					e.n1psalesmiddle = parseInt(this.middlelevel(naver1p.map(ele => ele.long*ele.price))/7*30).toLocaleString();
					
				}
			})
			setTimeout(()=>{
				this.loadingtext = '키워드 별 쿠팡 판매량 분석 중';
				this.loadingrate = '';
				this.showspinner = false;
				this.showlist = true;
			},1000)


		},
		async sleep(ms) {
			return new Promise((r) => setTimeout(r, ms));
		},
		async importresults(arr){
			console.log(arr)
			await this.sleep(1000);
			this.loadingtext = '키워드 별 쿠팡 판매량 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
			window.postMessage({greeting: "keywordanalysisNomad",data:arr,auth:this.auth,analysisType:this.analysisType},"*",)
		},
		changelines(){
			this.rowlines = [];
			for(var i=0;i<this.addlines;i++){
				this.rowlines.push({targetKeyword:'',productName:'',itemId:'',productId:'',vendorItemId:'',rank:[],adqty:'',adplace:'',productqty:'',rocketqty:'',showspinner:false,totalrank:[]})
			}
		},
		resetitem(i){
			this.rowlines[i].targetKeyword = '';
			this.rowlines[i].productName = '';
			this.rowlines[i].itemId = '';
			this.rowlines[i].productId = '';
			this.rowlines[i].rank = [];
			this.rowlines[i].adqty = '';
			this.rowlines[i].adplace = '';
			this.rowlines[i].productqty = '';
			this.rowlines[i].rocketqty = '';
			this.rowlines[i].showspinner = false;
			this.rowlines[i].totalrank = [];
		},
		deleteitem(i){
			this.rowlines = this.rowlines.slice(0,i).concat(this.rowlines.slice(i+1))
			if(this.rowlines.length == 0){
				this.showlist = false;
			}
			this.addlines--
		},	
		search(){
			if(!this.targetKeyword){
				alert('분석할 키워드를 입력해 주세요')
				return
			}
			window.postMessage({greeting: "getCategoryOptimization",
				companyid: this.$store.state.companyid,
				suppliercode: this.$store.state.suppliercode,
				info : {
					keyword:this.targetKeyword
				},
			},"*",)

			this.loadingtext = '키워드 카테고리 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
		},
		searchresult(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})	
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				this.$store.state.productdb.forEach(e => {
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
							}
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},   
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			this.rowlines.forEach(e => {
				arr.push({
					키워드 : e.targetKeyword,
					쿠팡1p윙비율:e.wingrate,
					쿠팡1p윙제품수 : e.wing1p,
					쿠팡1p로켓제품수 : e.rocket1p,
					쿠팡1p그로스제품수 : e.growth1p,
					쿠팡광고비율 : e.adrate,
					쿠팡광고상품수 : e.adqty,
					쿠팡광고구좌수: e.adplace,
					쿠팡광고CPC단가_추천: e.cpc,
					쿠팡광고CPC단가_최대: e.cpchigh,
					쿠팡광고CPC단가_최저: e.cpclow,
					쿠팡로켓비율: e.rocketrate,
					쿠팡로켓상품수: e.rocketqty,
					쿠팡전체상품수: e.totalqty,

					쿠팡1p리뷰수_평균: e.review1p,
					쿠팡1p리뷰수_최대: e.review1phigh,
					쿠팡1p리뷰수_최저: e.review1plow,
					쿠팡1p리뷰수_중간: e.review1pmiddle,

					쿠팡1p판매가_평균: e.price1p,
					쿠팡1p판매가_최대: e.price1phigh,
					쿠팡1p판매가_최저: e.price1plow,
					쿠팡1p판매가_중간: e.price1pmiddle,

					네이버1p판매가_평균: e.pricen1p,
					네이버1p판매가_최대: e.pricen1phigh,
					네이버1p판매가_최저: e.pricen1plow,
					네이버1p판매가_중간: e.pricen1pmiddle,

					네이버1p월판매수량_평균: e.n1psalesqty,
					네이버1p월판매수량_최대: e.n1psalesqtyhigh,
					네이버1p월판매수량_최저: e.n1psalesqtylow,
					네이버1p월판매수량_중간: e.n1psalesqtymiddle,
					네이버1p월매출_평균: e.n1psales,
					네이버1p월매출_최대: e.n1psaleshigh,
					네이버1p월매출_최저: e.n1psaleslow,
					네이버1p월매출_중간: e.n1psalesmiddle,					
				})
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '키워드분석')
			XLSX.writeFile(workBook, '키워드분석' + '_' + getToday() + '.xlsx')
		},
		settinglist(){
			
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>